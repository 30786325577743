<template>
  <div class="my-account">
    <div class="row margin-0 justify-content-center">
      <div class="col-md-12 col-lg-10 col-xl-10 col-12 pad-0">
        <div class="row margin-0">
          <div class="col-md-2 col-sm-3 pad-0">
            <div class="user-profile">
              <div class="user-info">
                <img src="/user.png" class="user-pic" alt />
                <h6>{{profile.name}}</h6>
              </div>
              <div class="user-menu">
                  <ul>
                      <li @click="tab='about'" :class="{'active':tab==='about'}"> 
                      <template v-if="profile && profile.user_type==='BUSINESS'"><ion-icon name="business-outline"></ion-icon> About Business</template>
                      <template v-else><ion-icon name="person-circle"></ion-icon> About Me</template> </li>
                      <li @click="tab='billing'" :class="{'active':tab==='billing'}"><ion-icon name="card-outline"></ion-icon> Payments &amp; Billing</li>
                      <li @click="tab='change-password'" :class="{'active':tab==='change-password'}"><ion-icon name="key"></ion-icon> Change Password</li>
                      <li class="text-danger" @click="logout()">Logout</li>
                  </ul>
              </div>
            </div>
          </div>
          <div class="col-md-10 col-sm-9 pad-0">
              <div class="detail-view" v-if="tab==='change-password'">
                <div class="row margin-0">
                  <div class="col-12 pad-10">
                    <h6>Change Password</h6>
                  </div>
                  <div class="col-md-4 col-12 pad-5">
                    <div class="form-group">
                      <label for="">Password</label>
                      <input type="password" v-model="password" class="form-control" placeholder="Password">
                      <span class="error" v-if="error && error.password">{{error.password}}</span>
                    </div>
                  </div>
                  <div class="col-md-4 col-12 pad-5">
                    <div class="form-group">
                      <label for="">Confirm Password</label>
                      <input type="password" v-model="re_enter_password" class="form-control" placeholder="Confirm Password">
                      <span class="error" v-if="error && error.re_enter_password">{{error.re_enter_password}}</span>
                    </div>
                  </div>
                  <div class="col-12 pad-5">
                    <button class="btn btn-success" @click="updatePassword()">Update Password</button>
                  </div>
                </div>
              </div>
              <div class="detail-view" v-else-if="tab==='about'">
                <div class="pad-15" v-if="edit_profile">
                  <h6>Edit Profile <span class="f-right c-pointer" @click="edit_profile=false"><span class="fa fa-times"></span> Cancel</span></h6>
                  <div class="row margin-0">
                    <div class="col-md-4 col-12 pad-left-0">
                      <div class="form-group">
                        <label for="" v-if="profile.user_type==='BUSINESS'">Business Name<span class="req">*</span></label>
                        <label for="" v-else>Full Name<span class="req">*</span></label>
                        <input type="text" v-model="profile.name" class="form-control">
                        <span class="error" v-if="error && error.name">{{error.name}}</span>
                      </div>
                    </div>
                    <div class="col-md-4 col-12 pad-left-0">
                      <div class="form-group">
                        <label for="">Email Address</label>
                        <input type="email" v-model="profile.email" class="form-control" placeholder="Email Address">
                        <span class="error" v-if="error && error.email">{{error.email}}</span>
                      </div>
                    </div>
                    <div class="col-md-4 col-12 pad-left-0">
                      <div class="form-group">
                        <label for="">Mobile Number</label>
                        <p><b>+91 {{profile.mobile}}</b></p>
                      </div>
                    </div>
                    <template v-if="profile.user_type === 'BUSINESS'">
                      <div class="col-md-12 col-12 pad-left-0">
                        <div class="form-group">
                          <label for="">Store URL<span class="req">*</span></label>
                          <input type="text" v-model="profile.path" class="form-control">
                          <span class="txt-grey">https://ebechana.com/store/{{profile.path}}/</span>
                          <span class="error" v-if="error && error.path">{{error.path}}</span>
                        </div>
                      </div>
                      <div class="col-md-4 col-12 pad-left-0">
                        <div class="form-group">
                            <label for="">State<span class="req">*</span></label>
                            <select name="" v-model="profile.data.address.state" @change="profile.data.address.city=profile.data.address.area=null" id="" class="form-control">
                                <template v-for="(item, index) in states">
                                    <option :key="index" :value="index">{{index}}</option>
                                </template>
                            </select>
                            <span class="error" v-if="error && error.address && error.address.state">{{error.address.state}}</span>
                        </div>
                      </div>
                      <div class="col-md-4 col-12 pad-left-0">
                          <div class="form-group">
                              <label for="">City<span class="req">*</span></label>
                              <select name="" v-model="profile.data.address.city" id="" class="form-control">
                                  <template v-for="(item, index) in states[profile.data.address.state]">
                                      <option :key="index" :value="item">{{item}}</option>
                                  </template>
                              </select>
                              <span class="error" v-if="error && error.address && error.address.city">{{error.address.city}}</span>
                          </div>
                      </div>
                      <div class="col-md-4 col-12 pad-left-0">
                          <div class="form-group">
                              <label for="">Pincode<span class="req">*</span></label>
                              <input type="number" class="form-control" v-model="profile.data.address.pincode" placeholder="Pincode">
                              <span class="error" v-if="error && error.address && error.address.pincode">{{error.address.pincode}}</span>
                          </div>
                      </div>
                      <div class="col-md-4 col-12 pad-left-0">
                          <div class="form-group">
                              <label for="">Address<span class="req">*</span></label>
                              <input type="text" class="form-control" v-model="profile.data.address.address" placeholder="Address">
                              <span class="error" v-if="error && error.address && error.address.address">{{error.address.address}}</span>
                          </div>
                      </div>
                      <div class="col-md-4 col-12 pad-left-0">
                          <div class="form-group">
                              <label for="">Website</label>
                              <input type="text" class="form-control" v-model="profile.data.website" placeholder="Website url">
                              <span class="error" v-if="error && error.website">{{error.website}}</span>
                          </div>
                      </div>
                      <div class="col-md-4 col-12 pad-left-0">
                          <div class="form-group">
                              <label for="">Facebook</label>
                              <input type="text" class="form-control" v-model="profile.data.facebook" placeholder="facebook page url">
                              <span class="error" v-if="error && error.facebook">{{error.facebook}}</span>
                          </div>
                      </div>
                    </template>
                      <div class="col-12 text-center pad-5">
                        <button class="btn btn-success" @click="updateProfile()">Update</button>
                        <button class="btn btn-danger margin-left-10" @click="edit_profile=false">Cancel</button>
                      </div>
                  </div>
                </div>
                <div v-else class="pad-15">
                  <h6>My Details <span class="f-right c-pointer" @click="edit_profile=true"><span class="fa fa-pencil"></span> Edit Profile</span></h6>
                  <div class="info-block" v-if="profile && profile.user_type==='BUSINESS'">
                      <label for="">Business Name</label>
                      <span>{{profile.name}}</span>
                  </div>
                  <div class="info-block" v-else>
                      <label for="">Name</label>
                      <span>{{profile.name}}</span>
                  </div>
                  <div class="info-block">
                      <label for="">Mobile</label>
                      <span>+ 91 {{profile.mobile}}</span>
                  </div>
                  <div class="info-block" v-if="profile.email">
                      <label for="">Email</label>
                      <span>{{profile.email}}</span>
                  </div>
                  <div class="info-block">
                      <label for="">Account Type</label>
                      <span v-if="profile.user_type==='BUSINESS'">Business</span>
                      <span v-else>Personal</span>
                  </div>
                  <div class="info-block" v-if="profile && profile.user_type==='BUSINESS'">
                      <label for="">My Store URL</label>
                      <a target="_blank" :href="`https://ebechana.com/store/${profile.path}/`">https://ebechana.com/store/{{profile.path}}/</a>
                  </div>
                </div>
              </div>
              <div class="detail-view" v-else>
                  <p>Will be added soon...</p>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        profile: JSON.parse(localStorage.getItem('user_details')) || null,
        tab: 'about',
        payload: null,
        edit_profile: false,
        products: null,
        error: null,
        states: null,
        password: null,
        re_enter_password: null,
    }
  },
  created() {
    document.title = "My Account - Free classifieds for india";
    // this.getProfile();
    // this.getProducts();
    if(this.profile.user_type === 'BUSINESS' && (this.profile.data === null || this.profile.data === {})) {
      this.profile.data = {
        category: null,
        website: null,
        facebook: null,
        address: {
          address: null,
          city: null,
          state: null,
          pincode: null,
        }
      }
    }
    this.states = this.$constants.states;
  },
  methods: {
    logout() {
      localStorage.clear();
      window.location.href = '/';
    },
    getProducts() {
      this.$cs.myproduct.list({
        sort: 'created_on'
      })
      .then(res => {
        this.products = res.data;
      })
    },
    updatePassword() {
      const that = this;
      if(that.validatePassword()) {
          that.$cs.profile.changePassword({
              new_password: that.password})
          .then(res=>{
            that.tab = 'about';
          },err=>{
              console.error(err);
          })
      }
    },
    updateProfile() {
      const that = this;
      if(that.validateData()) {
          that.$cs.profile.update({
              data: that.profile})
          .then(res=>{
            that.getProfile();
            that.edit_profile=false;
          },err=>{
              console.error(err);
          })
      }
    },
    getProfile() {
      const that = this;
      that.$cs.profile.list({})
      .then(res=>{
        that.profile=res.data;
        localStorage.setItem('user_details', JSON.stringify(res.data));
        if(this.profile.user_type === 'BUSINESS' && (this.profile.data === null || this.profile.data === {})) {
          this.profile.data = {
            category: null,
            website: null,
            facebook: null,
            address: {
              address: null,
              city: null,
              state: null,
              pincode: null,
            }
          }
        }
      },err=>{
          console.error(err);
      })
    },
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      console.log(that.profile);
      if (that.profile.name === null || that.profile.name === "") {
        this.error.name = "Name should not be blank";
        proceed = false;
      }
      if(that.profile.user_type === 'BUSINESS') {
        that.error.address = {};
        if (that.profile.path === null || that.profile.path === "") {
          this.error.path = "Store URL should not be blank";
          proceed = false;
        }
        if (that.profile.data.address.state === null || that.profile.data.address.state === "") {
          this.error.address.state = "State should not be blank";
          proceed = false;
        }
        if (that.profile.data.address.city === null || that.profile.data.address.city === "") {
          this.error.address.city = "City should not be blank";
          proceed = false;
        }
        if (that.profile.data.address.address === null || that.profile.data.address.address === "") {
          this.error.address.address = "Address should not be blank";
          proceed = false;
        }
        if (that.profile.data.address.pincode === null || that.profile.data.address.pincode === "") {
          this.error.address.pincode = "Pincode should not be blank";
          proceed = false;
        }
      }
      return proceed;
    },
    validatePassword() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (that.password === null || that.password === "") {
        this.error.password = "Password should not be blank";
        proceed = false;
      }
      if (that.re_enter_password === null || that.re_enter_password === "") {
        this.error.re_enter_password = "Confirm Password should not be blank";
        proceed = false;
      }
      return proceed;
    },
  }
}
</script>
<style lang="stylus" scoped>
.my-account
    padding: 3vh 0px
    .user-profile
        min-height 60vh
        border 1px solid #ddd
        border-right none
        background-color #ffffff
        padding 20px 0px 0px
        .user-info
            text-align center
            .user-pic
                max-width 50%
                border 1px solid #ddd
                border-radius 50%
                margin-bottom 10px
            h4
                font-weight bold
                letter-spacing 0.5px
        .user-menu
            padding 10px 0px 0px
            border-bottom 1px solid #ddd
            ul
                list-style none
                padding 0px
                margin 0px
                li
                    padding 10px 20px
                    font-size 1em
                    border-top 1px solid #ddd
                    color #666
                    font-weight 500
                    cursor pointer
                    &.active
                        color #000
    .detail-view
        border 1px solid #ddd
        background-color white
        min-height 60vh
        padding 10px
        overflow hidden
        .info-block
            padding 10px 0px
            label
                font-size 0.9em
                display block
                padding 0px
                margin 0px
                color #888
            span
                font-weight bold
                font-size 1.2em
                letter-spacing 0.5px
        .table-head
          border 1px solid #ddd
          .trr
            padding 15px 10px
            letter-spacing 0.5px
        .table-body
          border-bottom 1px solid #ddd
        .data-list
          border 1px solid #ddd
          max-height 70vh
          overflow-y scroll
@media (max-width: 767px)
  .my-account
    padding: 0px
    .user-profile
      min-height auto
      padding-bottom 10px
      .user-menu-xs
        padding 10px 20px
      .user-pic
        max-width 30%!important
</style>
